<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div
      id="custom-modal-body"
      class="custom-modal-body shadow position-relative"
    >
      <div
        class="
          p-3
          bg-primary
          lead
          text-center
          fw-medium
          text-light
          position-relative
        "
      >
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="left: 0; top: 0"
          @click="deleteAssessment()"
          v-if="assessment"
        >
          <i class="fa fa-trash-alt text-danger" />
        </button>
        {{ assessment ? "Edit" : "Add" }} tutor assessment
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="closePreview()"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="p-4">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="title" class="form-label">
                Title<sup class="text-danger">*</sup>
              </label>
              <input
                type="text"
                class="form-control"
                id="title"
                v-model="models.title"
              />
            </div>
          </div>
          <div class="col-12 col-md-auto">
            <div class="mb-3">
              <label for="level" class="form-label">
                Level<sup class="text-danger">*</sup>
              </label>
              <select class="form-select" v-model="models.level">
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advance">Advance</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-auto">
            <div class="mb-3">
              <label for="status" class="form-label">
                Status<sup class="text-danger">*</sup>
              </label>
              <select class="form-select" v-model="models.status">
                <option value="Draft">Draft</option>
                <option value="Published">Published</option>
              </select>
            </div>
          </div>
        </div>
        <div class="py-3">
          <p class="text-primary fw-medium mb-0">
            Questions
            <span
              class="badge bg-light border text-dark fw-normal py-2 px-3 ms-2"
            >
              {{ models.questions.length }}
            </span>
          </p>
        </div>
        <div class="card bg-light mb-3" v-if="models.questions.length == 0">
          <div class="card-body text-center text-muted fst-italic small">
            No question yet
          </div>
        </div>
        <div
          class="card bg-light mb-3"
          v-for="(question, index) in models.questions"
          :key="question._id"
        >
          <div class="card-header position-relative small fw-medium">
            Question #{{ index + 1 }}
            <button
              class="btn py-0 px-4 h-100 position-absolute"
              style="right: 0; top: 0"
              @click="removeQuestion(question._id, index + 1)"
            >
              <i class="fa fa-trash-alt text-danger" />
            </button>
          </div>
          <div class="row mx-0 card-body p-0 card-question">
            <div class="col-12 col-lg-auto py-3 ps-3 pe-3 pe-lg-4">
              <div class="mb-3">
                <label for="text" class="form-label fw-medium small">
                  Section
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="section"
                  v-model="question.section"
                />
              </div>
              <div class="mb-5 position-relative">
                <label for="text" class="form-label fw-medium small">
                  Media
                </label>
                <div
                  class="ratio ratio-1x1 rounded shadow-sm border mx-auto"
                  style="width: 220px"
                >
                  <button
                    class="
                      btn
                      bg-light
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                      p-0
                    "
                    @click="selectPoster(question._id)"
                  >
                    <template v-if="question.mediaPreview || question.media">
                      <img
                        v-if="question.mediaPreview"
                        class="image-fit mh-100"
                        :src="question.mediaPreview"
                      />
                      <img
                        v-else-if="question.media"
                        class="image-fit mh-100"
                        :src="Helper.formatMediaUrl(question.media)"
                      />
                      <button
                        class="
                          btn btn-link
                          text-danger text-decoration-none
                          position-absolute
                        "
                        style="bottom: -35px"
                        @click.stop="clearPreview(question._id)"
                      >
                        <i class="fas fa-times me-2" />Remove
                      </button>
                    </template>
                    <template v-else>
                      <i class="fas fa-plus mb-2" />
                      <small class="font-monospace text-center lh-sm">
                        Add media
                      </small>
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="col py-3 pe-3 ps-3 ps-lg-4">
              <div class="mb-4">
                <label for="text" class="form-label fw-medium small">
                  Question<sup class="text-danger">*</sup>
                </label>
                <textarea
                  type="text"
                  class="form-control no-resize"
                  id="text"
                  rows="3"
                  v-model="question.text"
                ></textarea>
              </div>
              <p class="fw-medium small">
                Answers
                <small class="fw-normal">(Tick the correct answer)</small>
                <sup class="text-danger">*</sup>
              </p>
              <div class="card mb-3" v-if="question.answers.length == 0">
                <div class="card-body text-center text-muted fst-italic small">
                  No answer yet
                </div>
              </div>
              <div
                class="input-group mb-3"
                v-for="answer in question.answers"
                :key="answer._id"
              >
                <div
                  class="
                    input-group-text
                    text-uppercase
                    justify-content-center
                    hover-parent
                  "
                  style="width: 40px"
                >
                  {{ answer.sequence }}
                  <button
                    class="
                      btn btn-sm
                      bg-light
                      position-absolute
                      top-0
                      start-0
                      h-100
                      w-100
                      hover-content
                    "
                    @click="removeAnswer(question._id, answer)"
                  >
                    <i class="fas fa-trash-alt text-danger"></i>
                  </button>
                </div>
                <input type="text" class="form-control" v-model="answer.text" />
                <button
                  class="input-group-text"
                  @click="setCorrectAnswer(question._id, answer.sequence)"
                >
                  <i
                    class="fas fa-check-square text-success"
                    v-if="question.correct_answer == answer.sequence"
                  />
                  <i class="fas fa-square text-white" v-else />
                </button>
              </div>
              <div class="text-end">
                <button
                  class="btn btn-sm bg-teal px-3"
                  @click="addAnswer(question._id)"
                >
                  <i class="fas fa-plus me-2" />
                  Add answer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-3 text-end">
          <button class="btn btn-sm bg-purple px-3" @click="addQuestion()">
            <i class="fas fa-plus me-2" />
            Add question
          </button>
        </div>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light shadow" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-primary" @click="submitChange()">
            <i class="fas fa-save me-2" />
            Submit
          </button>
        </div>
      </div>
      <loading-spinner class="text-light position-fixed" v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: ["assessment"],
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      models: {
        title: null,
        level: "Beginner",
        status: "Draft",
        questions: [],
      },
      questionFields: {
        _id: null,
        section: null,
        text: null,
        correct_answer: null,
        media: null,
        mediaPreview: null,
        mediaInput: null,
        answers: [],
      },
      answerFields: {
        _id: null,
        sequence: null,
        text: null,
      },
    };
  },
  methods: {
    addAnswer(questionId) {
      let questionIndex = this.models.questions.findIndex(
        (q) => q._id == questionId
      );

      let question = this.models.questions.find((q) => q._id == questionId);

      if (question) {
        let newAnswer = JSON.parse(JSON.stringify(this.answerFields));

        newAnswer._id = Date.now() + question.answers.length;

        newAnswer.sequence = this.Helper.alphabetSequence(
          question.answers.length
        );

        question.answers.push(newAnswer);

        this.$set(this.models.questions, questionIndex, question);
      }
    },
    removeAnswer(questionId, answer) {
      Swal.fire({
        title: "<h5>Delete answer</h5>",
        html: `
          <p>
            Do you want to remove <strong>Answer <span class="text-uppercase">${answer.sequence}</span></strong>?
          </p>
          <div class="mb-0 alert alert-info lh-sm small">
            <i class="fas fa-exclamation-circle text-muted me-2"></i>
            Answer sequence will be adjusted accordingly
          </div>
        `,
        showCancelButton: true,
        reverseButtons: true,
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          let questionIndex = this.models.questions.findIndex(
            (q) => q._id == questionId
          );

          let question = this.models.questions.find((q) => q._id == questionId);

          if (question) {
            const currentCorrectAnswer = question.answers.find(
              (a) => a.sequence == question.correct_answer
            );

            if (question.correct_answer == answer.sequence) {
              question.correct_answer = null;
            }

            question.answers = question.answers.filter(
              (a) => a._id != answer._id
            );

            question.answers = question.answers.map((a, index) => {
              a.sequence = this.Helper.alphabetSequence(index);

              if (currentCorrectAnswer && currentCorrectAnswer._id == a._id) {
                question.correct_answer = a.sequence;
              }

              return a;
            });

            if (
              !question.answers.find(
                (a) => a.sequence == question.correct_answer
              )
            ) {
              question.correct_answer = null;
            }

            this.$set(this.models.questions, questionIndex, question);
          }
        }
      });
    },
    removeQuestion(questionId, questionNo) {
      Swal.fire({
        title: "<h5>Delete question</h5>",
        html: `Do you want to remove <strong>Question #${questionNo}</strong>?`,
        showCancelButton: true,
        reverseButtons: true,
        icon: "question",
      }).then((result) => {
        if (result.isConfirmed) {
          this.models.questions = this.models.questions.filter(
            (q) => q._id !== questionId
          );
        }
      });
    },
    addQuestion() {
      let newQuestion = JSON.parse(JSON.stringify(this.questionFields));

      newQuestion._id = Date.now();

      for (let n = 0; n < 4; n++) {
        let newAnswer = JSON.parse(JSON.stringify(this.answerFields));

        newAnswer._id = Date.now() + n;

        newAnswer.sequence = this.Helper.alphabetSequence(n);

        newQuestion.answers.push(newAnswer);
      }

      this.models.questions.push(newQuestion);
    },
    setCorrectAnswer(questionId, answerSequence) {
      const questionIndex = this.models.questions.findIndex(
        (q) => q._id == questionId
      );

      let q = this.models.questions.find((q) => q._id == questionId);

      if (q) {
        q.correct_answer = answerSequence;

        this.$set(this.models.questions, questionIndex, q);
      }
    },
    clearPreview(questionId) {
      let questionIndex = this.models.questions.findIndex(
        (q) => q._id == questionId
      );

      let question = this.models.questions.find((q) => q._id == questionId);

      if (question) {
        question.media = null;

        question.mediaInput = null;

        question.mediaPreview = null;

        this.$set(this.models.questions, questionIndex, question);
      }
    },
    posterChanged(e, questionId) {
      const questionIndex = this.models.questions.findIndex(
        (q) => q._id == questionId
      );

      let question = this.models.questions.find((q) => q._id == questionId);

      if (question) {
        let file = e.target.files[0];

        question.mediaInput = file;

        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = (readerEvent) => {
          question.mediaPreview = readerEvent.target.result;

          this.$set(this.models.questions, questionIndex, question);
        };
      }
    },
    selectPoster(questionId) {
      let input = document.createElement("input");

      input.type = "file";

      input.accept = "image/*,video/*,.pdf";

      input.onchange = (e) => this.posterChanged(e, questionId);

      input.click();
    },
    deleteAssessment() {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this assessment?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "bg-danger",
        },
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;

            return this.API.put(`tutor-assessments/${this.assessment._id}`, {
              status: "Deleted",
            })
              .catch((error) => {})
              .finally(() => {
                Swal.fire("Tutor assessment deleted", "", "success");

                this.$emit("delete", this.assessment);
              });
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
        });
    },
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
    async submitChange() {
      let formErrors = [];

      if (!this.models.title) {
        formErrors.push("Title is required");
      }

      if (!this.models.level) {
        formErrors.push("Level is required");
      }

      if (!this.models.status) {
        formErrors.push("Status is required");
      }

      if (this.models.questions.length === 0) {
        formErrors.push("No question submitted");
      }

      if (
        this.models.questions.some((q) => {
          return !q.text;
        })
      ) {
        formErrors.push("Question must have text");
      }

      if (
        this.models.questions.some((q) => {
          return q.answers.filter((a) => !a.text).length > 0;
        })
      ) {
        formErrors.push("Please delete empty answers");
      }

      if (
        this.models.questions.some((q) => {
          return !q.correct_answer;
        })
      ) {
        formErrors.push("No correct answer selected");
      } else if (
        this.models.questions.some((q) => {
          return (
            !q.answers.find((a) => a.sequence == q.correct_answer) ||
            !q.answers.find((a) => a.sequence == q.correct_answer).text
          );
        })
      ) {
        formErrors.push("Correct answer have no text");
      }

      if (formErrors.length > 0) {
        Swal.fire({
          title: "<h5>Invalid form submitted</h5>",
          html: `
          <div class="alert alert-danger lh-sm">
            ${formErrors
              .map((e) => "<p class='mb-0 small'>" + e + "</p>")
              .join("")}
          </div>
          `,
          icon: "error",
        });
      } else {
        let body = this.models;

        Swal.fire({
          title: `<h5>${
            body.status == "Published"
              ? "Save and publish assessment?"
              : "Save assessment as draft?"
          }</h5>`,
          icon: "question",
          showCancelButton: true,
          showDenyButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
          denyButtonText: `Save as ${
            body.status == "Published" ? "draft" : "published"
          }`,
          denyButtonColor: body.status == "Published" ? "" : "blue",
        }).then(async (result) => {
          if (result.isDismissed) {
            return;
          }

          if (result.isDenied) {
            body.status = body.status == "Published" ? "Draft" : "Published";
          }

          try {
            this.isLoading = true;

            let method = "post";

            let url = "tutor-assessments";

            if (this.assessment) {
              method = "put";

              url = `tutor-assessments/${this.assessment._id}`;
            }

            const questionwWithNewMedia = body.questions.filter(
              (q) => q.mediaInput
            );

            if (questionwWithNewMedia.length > 0) {
              const mediaPosts = questionwWithNewMedia.map((question) => {
                const formData = new FormData();

                formData.append(
                  "files",
                  question.mediaInput,
                  question.mediaInput.name
                );

                let promise = new Promise(async (resolve) => {
                  const { data } = await this.API.postForm("upload", formData);

                  let _question = body.questions.find(
                    (q) => q._id == question._id
                  );

                  if (_question) {
                    _question.media = data[0];

                    delete _question.mediaInput;

                    delete _question.mediaPreview;
                  }

                  resolve();
                });

                return promise;
              });

              await Promise.all(mediaPosts);
            }

            const { data } = await this.API[method](url, body);

            Swal.fire(
              `Tutor assessment ${this.assessment ? "updated" : "added"}`,
              "",
              "success"
            ).then(() => {
              if (this.assessment) {
                this.closePreview(data);
              } else {
                this.$emit("add", data);
              }
            });
          } catch (err) {
            Swal.fire(
              "Opps. An error occured",
              JSON.stringify(err.response ? err.response.data : err.message),
              "error"
            );
          } finally {
            this.isLoading = false;
          }
        });
      }
    },
  },
  async mounted() {
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");

    if (this.assessment) {
      for (const key in this.models) {
        if (Object.hasOwnProperty.call(this.models, key)) {
          this.models[key] = JSON.parse(
            JSON.stringify(this.assessment[key] || "")
          );
        }
      }
    }
  },
  beforeDestroy() {
    this.closePreview();

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");
  },
};
</script>