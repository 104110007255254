<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading assessment list
  </loading-spinner>
  <div v-else>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search by title"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div class="ms-2" style="width: 10rem">
        <select class="form-select" v-model="filterStatus">
          <option :value="null">All</option>
          <option value="Published">Published</option>
          <option value="Draft">Draft</option>
        </select>
      </div>
    </div>
    <div
      class="
        pb-3
        d-flex
        justify-content-between
        align-items-center
        flex-column flex-sm-row
      "
    >
      <div>
        <span class="badge bg-dark shadow-sm py-2 fw-normal">
          Total Assessments: {{ assessments.length }}
        </span>
        <span class="badge bg-success shadow-sm py-2 ms-2 fw-normal">
          Published:
          {{
            assessments.filter((assessment) => assessment.status == "Published")
              .length
          }}
        </span>
        <span
          class="badge bg-light border text-dark shadow-sm py-2 ms-2 fw-normal"
        >
          Draft:
          {{
            assessments.filter((assessment) => assessment.status == "Draft")
              .length
          }}
        </span>
      </div>
      <button
        class="btn btn-sm btn-primary px-3 mt-2 mt-sm-0"
        @click="viewAssessment()"
      >
        <i class="fas fa-plus me-2" />
        Add assessment
      </button>
    </div>
    <div
      class="
        table-responsive
        rounded
        shadow-sm
        rounded
        border border-light
        position-relative
      "
    >
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th>Title</th>
            <th class="text-center ps-0">Level</th>
            <th class="text-center ps-0">Submission</th>
            <th class="text-center ps-0">Questions</th>
            <th class="text-center ps-0">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="assessment in computedAssessments" :key="assessment._id">
            <td class="text-nowrap text-truncate">
              <button
                class="
                  btn btn-link
                  p-0
                  w-100
                  lh-sm
                  clickable
                  text-start text-decoration-none text-truncate
                "
                :title="assessment.title"
                v-html="assessment.title"
                @click="viewAssessment(assessment)"
              ></button>
            </td>
            <td class="text-center ps-0" style="width: 1%">
              <div
                class="badge py-2 px-3"
                :class="`bg-${Helper.getLevelColor(assessment.level)}`"
                v-if="assessment.level"
              >
                {{ assessment.level }}
              </div>
            </td>
            <td class="text-center ps-0" style="width: 1%">
              <button
                class="
                  badge
                  bg-secondary
                  border border-secondary
                  text-dark
                  fw-normal
                  p-2
                "
                @click="viewSubmission(assessment)"
              >
                {{
                  assessment.crt_assessments
                    ? assessment.crt_assessments.length
                    : 0
                }}
              </button>
            </td>
            <td class="text-center ps-0" style="width: 1%">
              <span class="badge bg-light border text-dark fw-normal p-2">
                {{ assessment.questions ? assessment.questions.length : 0 }}
              </span>
            </td>
            <td
              style="width: 1%"
              class="text-nowrap text-truncate text-center ps-0"
              :class="
                assessment.status == 'Published' ? 'text-success' : 'text-muted'
              "
            >
              {{ assessment.status }}
            </td>
          </tr>
          <tr v-if="computedAssessments.length < 1">
            <td colspan="5" class="text-center fst-italic text-muted">
              No assessment found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <manage-tutor-assessment-form
      v-if="showAssessmentModal"
      :assessment="currentAssessment"
      @close="closeAssessmentModal"
      @update="updateAssessmentDetails"
      @delete="closeAssessmentModal($event, 'delete')"
      @add="closeAssessmentModal($event, 'add')"
    />
    <view-tutor-submission
      v-if="showSubmissionModal"
      :assessment="currentAssessment"
      @close="closeSubmissionModal"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";
import ManageTutorAssessmentForm from "@/components/Modals/ManageTutorAssessmentForm";
import ViewTutorSubmission from "@/components/Modals/ViewTutorSubmission";

export default {
  components: {
    LoadingSpinner,
    MediaViewer,
    ManageTutorAssessmentForm,
    ViewTutorSubmission,
  },
  data() {
    return {
      isLoading: false,
      assessments: [],
      search: "",
      currentAssessment: null,
      showAssessmentModal: false,
      showSubmissionModal: false,
      filterStatus: null,
    };
  },
  computed: {
    computedAssessments() {
      const search = this.search.toLowerCase();

      let result = [...this.assessments];

      if (this.filterStatus) {
        result = result.filter(
          (assessment) => assessment.status == this.filterStatus
        );
      }

      return result.filter((assessment) =>
        assessment.title.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    updateAssessmentDetails(assessment) {
      if (assessment) {
        this.currentAssessment = assessment;

        let assessmentIndex = this.assessments.findIndex(
          (w) => w._id == assessment._id
        );

        if (assessmentIndex > -1) {
          this.$set(this.assessments, assessmentIndex, assessment);
        }
      }
    },
    viewAssessment(assessment) {
      this.currentAssessment = assessment;

      this.showAssessmentModal = true;
    },
    closeAssessmentModal(assessment, type) {
      this.currentAssessment = null;

      if (assessment) {
        if (type == "add") {
          this.assessments.push(assessment);
        } else {
          const assessmentIndex = this.assessments.findIndex(
            (w) => w._id == assessment._id
          );

          if (assessmentIndex > -1) {
            if (type == "delete") {
              this.assessments = this.assessments.filter(
                (w) => w._id != assessment._id
              );
            } else {
              this.$set(this.assessments, assessmentIndex, assessment);
            }
          }
        }
      }

      this.showAssessmentModal = false;
    },
    viewSubmission(assessment) {
      this.currentAssessment = assessment;

      this.showSubmissionModal = true;
    },
    closeSubmissionModal() {
      this.showSubmissionModal = false;

      this.currentAssessment = null;
    },
    getAssessments() {
      this.isLoading = true;

      this.API.get("tutor-assessments?_limit=-1&status_ne=Deleted")
        .then((retVal) => {
          this.assessments = retVal.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getAssessments();
  },
};
</script>